<template>
    <div id="fiche-mission-page" class="pt-3 mb-6">
        <div class="bg-info text-center text-white" id="ariane">
            <b-button @click="getBack">
                <i class="fa fa-chevron-left"></i> retour
            </b-button>
            Pointages
        </div>
        <div id="list" class="mt-5">
            <router-link :to="declarationRoute" class="d-block d-lg-none mt-5">
                <b-card class="text-white form-group"
                    :class="{ 'bg-success': declarationSuccessClass, 'bg-danger': !declarationSuccessClass }">
                    <b-card-body>
                        <h5 class="d-flex align-items-center justify-content-center m-0">
                            <span class="align-items-center d-flex"><i class="fas fa-clock fa-2x  far mr-3"></i> {{
                            declarationButtonLabel }}</span>
                        </h5>
                    </b-card-body>
                </b-card>
            </router-link>

            <!--<router-link :to="{ name: 'declarationPrestations', params: { mode: 'declaration' } }" class="d-block d-lg-none">
                <b-card class="declarationPrestation form-group">
                    <div class="d-flex align-items-center justify-content-center m-0">
      <span class="align-items-center d-flex"><i class="fas fa-hand-sparkles fa-2x  far mr-3"></i> Déclarer une prestation supplémentaire</span>
                    </div>
                </b-card>
            </router-link>-->

            <div id="listClients">
                <h6 class="text-center text-white">Heures déclarées ( {{ getGlobalTimeString(true) }} <template
                        v-if="kms"> - {{kms}} kms</template>) </h6>
                <b-list-group class="mb-4">
                    <div v-for="pointage in pointages" :key="pointage.idintervention_ticking">
                        <router-link :to="{ name: 'fiche-client', params: { clientId: pointage.idcustomer, intervAdress: pointage.intervention_idadress } }"
                            class="list-group-item list-group-item-action ">
                            <div v-if="!pointage.has_anomaly"
                                class="centered-ico col-2 bg-green-alternative text-white"><i class="fa-2x fas fa-check"
                                    aria-hidden="true"></i></div>
                            <div v-else class="centered-ico col-2 bg-warning text-white"><i class="fa-2x fas fa-search"
                                    aria-hidden="true"></i>
                            </div>
                            <div class="align-items-center row text-dark">
                                <div class="col-10 offset-2">
                                    <span class="d-block text-truncate">{{
                                    getFormattedTitle(pointage.customer_title)
                                    }} {{
                                        getFormattedFirstname(pointage.customer_firstname)
                                        }} {{ pointage.customer_lastname }}</span>
                                    <div v-if="pointage.end_time_real">
                                        <span class="d-block text-truncate">{{ getTimeString(pointage) }}</span>
                                        <span class="d-block text-truncate text-muted">{{
                                        getFormattedDate(pointage.intervention_date)
                                        }} <br /> {{
                                            getFormattedHour(pointage.intervention_date, pointage.start_time_real)
                                            }} • {{
                                            getFormattedHour(pointage.intervention_date, pointage.end_time_real)
                                            }}</span>
                                        <span v-if="pointage.has_anomaly" class="text-warning">En cours de
                                            vérification</span>
                                        <span v-else class="text-success">Vérifié</span>
                                    </div>
                                    <div v-else>
                                        <span class="d-block text-truncate">En cours</span>
                                        <span class="d-block text-truncate text-muted">{{
                                        getFormattedDate(pointage.intervention_date)
                                        }}</span>
                                    </div>
                                </div>
                            </div>
                        </router-link>
                    </div>
                </b-list-group>
                <router-link :to="{ name: 'declaration-tardive'}" class="d-flex justify-content-center">
                    <a to="/home" class="text-center text-white text-underline">Il manque une prestation ?</a>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
import router from '../../router'
import pointageService from '../../services/pointageService'

export default {
    name: "Pointages",
    mounted() {
        this.$emit('menu', 'pointages')
        this.getInterventions()
        pointageService.getPointageEnCours().then((response) => {
            if (response.data.etat === 'OK' && response.data.data.idintervention_ticking) {
                this.declarationRoute = {
                    name: 'intervention',
                    params: { idIntervention: response.data.data.idintervention_ticking }
                }
                this.declarationButtonLabel = 'Déclarer mon départ'
                this.declarationSuccessClass = false
            }
        }).catch((error) => {
            this.$rollbar.error('Erreur 3061', error);
            this.$bvToast.toast('Une erreur est survenue, veuillez réessayer ultérieurement ou contacter le support.', {
                title: 'Erreur 3061',
                autoHideDelay: 5000,
                variant: 'danger'
            })
        })
    },
    data() {
        return {
            pointages: [],
            declarationRoute: { name: 'detection' },
            declarationButtonLabel: 'Déclarer mon arrivée',
            declarationSuccessClass: true,
            sortOptions: [
                { value: 'date', text: 'Date' },
                { value: 'name', text: 'Nom' }
            ],
            totalMinutes: 0,
            kms: 0
        }
    },
    methods: {
        getBack() {
            router.go(-1)
        },
        getInterventions() {
            pointageService.getHeuresDeclarees().then((response) => {
                if (response.data.etat === 'OK' && response.data.data) {
                    if (response.data.etat === 'OK') {
                        if (response.data.data) {
                            response.data.data.forEach((item) => {
                                this.totalMinutes += (this.getNbMinutes(item)) ? this.getNbMinutes(item) : 0
                                this.kms += item.kms * 1
                                if (!item.end_time_real)
                                    item.end_time_real = item.end_time_billable
                            })
                            response.data.data.sort((a, b) => {
                                if (this.$moment(a.intervention_date + ' ' + a.start_time_real).isBefore(this.$moment(b.intervention_date + ' ' + b.start_time_real))) {
                                    return 1
                                } else {
                                    return -1
                                }
                            })
                            this.pointages = response.data.data
                                                   }
                    } else {
                        this.$rollbar.error('Erreur 3062');
                        this.$bvToast.toast('Une erreur est survenue', {
                            title: 'Erreur 3062',
                            autoHideDelay: 5000,
                            variant: 'danger'
                        })
                    }
                }
            }).catch((error) => {
                this.$rollbar.error('Erreur 3063', error);
                this.$bvToast.toast('Une erreur est survenue, veuillez réessayer ultérieurement ou contacter le support.', {
                    title: 'Erreur 3063',
                    autoHideDelay: 5000,
                    variant: 'danger'
                })
            })
        },
        getNbMinutes(pointage) {
            const start = this.$moment(pointage.intervention_date + ' ' + pointage.start_time_real?.substring(0, 5))
            const end = this.$moment(pointage.intervention_date + ' ' + pointage.end_time_real?.substring(0, 5))
            if (end.format('HH:mm') === '00:00') end.add(1, 'day')

            return this.$moment.duration(this.$moment(end).diff(start)).asMinutes();
        },
        formatTime(hours, minutes, short = false) {
            if (short) return `${hours} h ${minutes} m`;
            return `${hours} heure${hours > 1 ? 's' : ''} ${minutes} minute${minutes > 1 ? 's' : ''}`;
        },
        getGlobalTimeString(short = false) {
            const hours = Math.floor(this.totalMinutes / 60);
            const minutes = Math.ceil(this.totalMinutes % 60);

            return this.formatTime(hours, minutes, short);
        },
        getTimeString(intervention) {
            const start = this.$moment(intervention.intervention_date + ' ' + intervention.start_time_real?.substring(0, 5))
            const end = this.$moment(intervention.intervention_date + ' ' + intervention.end_time_real?.substring(0, 5))
            if (end.format('HH:mm') === '00:00') end.add(1, 'day')

            const duration = this.$moment.duration(this.$moment.duration(this.$moment(end).diff(start)));

            const hours = Math.floor(duration.asHours());
            const minutes = Math.ceil(duration.asMinutes() % 60);

            return this.formatTime(hours, minutes);
        },
        getFormattedHour(date, hour) {
            return this.$moment(date + ' ' + hour).format('HH:mm')
        },
        getFormattedDate(date) {
            return this.$moment(date).format('dddd D MMMM')
        },
        getFormattedFirstname(firstname) {
            if (firstname === null)
                return ''

            return firstname.charAt(0).toUpperCase() + firstname.slice(1).toLowerCase()
        },
        getFormattedTitle(title) {
            if (!title || title === 'null') return ''
            return title + '.'
        }

    }
}
</script>

<style scoped>
#ariane {
    text-align: center;
    padding: 10px;
    position: fixed;
    font-size: 22px;
    top: 45px;
    left: 0;
    right: 0;
    z-index: 2;
}

@media (min-width: 992px) {
    #ariane {
        top: 80px;
    }
}

#ariane button {
    position: absolute;
    left: 15px;
    background: transparent;
    border: none;
}

#ariane button:focus {
    box-shadow: none;
}

#list {
    /*margin-top: 3.5rem;*/
}

.declarationPrestation {
    border: 1px solid #b70e0c;
    color: #b70e0c;
}
</style>
